<template>
    <div class="page-container app-h100">
        <iframe :src="url" class="app-wh100" frameborder="0"></iframe>
    </div>
</template>

<script>
    import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';

    export default {
        name: "innerIframe",
        components: {

        },
        data() {
            return {
                url: this.$route.query.url ? this.$route.query.url : '',
                title: this.$route.query.title ? this.$route.query.title : '',
            }
        },
        created () {

        },
        mounted() {
            if(this.title)
                document.title = this.title;
        },
        computed: {

        },
        methods: {

        }
    }
</script>

<style scoped>

</style>